<script>
	import { getContext, createEventDispatcher } from "svelte";

	import { localeContext } from "@xbs/lib-scheduler";
	import { Portal } from "@wx/svelte-core";
	import Line from "./Line.svelte";
	import TimelineEvent from "./TimelineEvent.svelte";
	import TimelineHeader from "./TimelineHeader.svelte";
	import MinuteLine from "../MinuteLine.svelte";
	export let model = {};
	export let unassignedToggle;
	export let selected;
	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	$: ({
		events,
		rows,
		dates,
		config,
		calendars,
		draggedItem,
		minuteLine,
		calculateMinutesLinePosition,
	} = model);
	$: ({ colsWidth, sectionWidth, header, unassignedCol } = config);

	const dispatch = createEventDispatcher();

	function toggle() {
		dispatch("toggleUnassigned");
	}

</script>

<div class="wx-event-calendar-wrapper" wx-table-auto-scroll>
	<table class="table">
		<thead class="wx-event-calendar-header">
			<TimelineHeader {colsWidth} {sectionWidth} {dates} {header} />
		</thead>
		<tbody class="wx-event-calendar-timeline-body" wx-timeline-body>
			{#each $events as event (event.eventData?.id)}
				<TimelineEvent {event} {calendars} {selected} />
			{/each}
			{#each rows as line (line.section?.id)}
				<div
					data-section-id={line.section.id}
					class="wx-event-calendar-timeline-row"
					style="height: {line.height}px;">
					<Line {...line} {dates} {sectionWidth} , {colsWidth} />
				</div>
			{/each}
			{#if minuteLine}
				<MinuteLine
					orientation="vertical"
					{calculateMinutesLinePosition} />
			{/if}
		</tbody>
	</table>
</div>
{#if !unassignedToggle && unassignedCol}
	<div class="wx-unassigned-events" on:click={toggle}>
		<i class="wxi-unindent wx-unassigned-collapse-icon" />
		<span class="wx-unassigned-text">{_('Unassigned events')}</span>
	</div>
{/if}
{#if $draggedItem[0]}
	<Portal>
		<TimelineEvent event={$draggedItem[0]} {calendars} dragged={true} />
	</Portal>
{/if}

<style>
	.wx-unassigned-events {
		width: 50px;
		height: 100%;
		overflow: hidden;
		box-shadow: var(--wx-box-shadow);
		background-color: var(--wx-background-alt);
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
		padding: 16px;
		cursor: pointer;
	}
	.wx-unassigned-collapse-icon {
		font-size: var(--wx-icon-size);
		color: var(--wx-icon-color);
	}
	.wx-unassigned-text {
		margin-top: 14px;
		position: absolute;
		font-weight: var(--wx-font-weight-md);
		writing-mode: vertical-rl;
	}
	.table {
		font-family: "Fraunces", serif;
		font-size: 125%;
		white-space: nowrap;
		margin: 0;
		border: none;
		border-spacing: 0;
		table-layout: fixed;
		font-family: var(--wx-font-family);
		font-size: var(--wx-font-size);
	}
	.wx-event-calendar-wrapper {
		width: 100%;
		overflow: auto;
		position: relative;
	}
	.wx-event-calendar-timeline-row {
		border-bottom: var(--wx-border);
		display: table-row;
		flex-shrink: 0;
		width: -moz-fit-content;
		width: fit-content;
		transition: 0.3s;
	}
	.wx-event-calendar-timeline-body {
		position: relative;
	}</style>
