<script>
	import { getContext } from "svelte";
	import { Popup } from "@wx/svelte-core";
	import { correctPopupPosition } from "@xbs/lib-dom";
	import { format, isPast } from "date-fns";
	import { localeContext, getEventStyles } from "@xbs/lib-scheduler";

	export let config = {};
	export let calendars = [];
	$: ({ pos, events, date } = config);
	const locale = getContext(localeContext);
	const _dateFnsLocale = locale.getRaw().dateFnsLocale;
	const templates = getContext("schedulerTemplates");
	const schedulerConfig = getContext("schedulerConfig");
	$: ({ readonly, dimPastEvents } = $schedulerConfig);
	let popup;
	$: if (popup) {
		pos = {
			...correctPopupPosition(pos, {
				width: popup.offsetWidth,
				height: popup.offsetHeight,
			}),
		};
	}
	$: if (calendars) {
		events = { ...events };
	}
	function getCalendar(event) {
		return calendars.find(type => type.id === event.type);
	}

</script>

<Popup area={pos}>
	<div bind:this={popup} class="wx-event-calendar-year-popup-wrapper">
		<div class="wx-event-calendar-year-popup-title">
			{format(date, 'd MMMM', { locale: _dateFnsLocale })}
		</div>
		{#each events.multievents as event (event.id)}
			<div
				data-id={event.id}
				data-id-type={typeof event.id}
				class="wx-event-calendar-multievent {readonly || event.readonly ? 'wx-event-calendar-readonly' : ''} wx-event-calendar-year-multievent "
				use:getEventStyles={{ event: { eventData: event }, backgroundFactor: 'background', calendars, dimPastEvents, isPast: isPast(event?.end_date) }}>
				<svelte:component
					this={templates.multievent}
					{event}
					calendar={getCalendar(event)}
					on:action />
			</div>
		{/each}
		{#each events.dayEvents as event (event.id)}
			<div
				data-id={event.id}
				data-id-type={typeof event.id}
				class="wx-event-calendar-event {readonly || event.readonly ? 'wx-event-calendar-readonly' : ''} wx-event-calendar-year-oneday-event"
				use:getEventStyles={{ event: { eventData: event }, backgroundFactor: 'border', calendars, dimPastEvents, isPast: isPast(event?.end_date) }}>
				<svelte:component
					this={templates.yearEvent}
					{event}
					calendar={getCalendar(event)}
					on:action />
			</div>
		{/each}
	</div>
</Popup>

<style>
	.wx-event-calendar-year-popup-wrapper {
		padding: 18px 24px;
	}
	.wx-event-calendar-year-popup-title {
		font-size: var(--wx-font-size-md);
		font-weight: 700;
		margin-bottom: 17px;
	}
	.wx-event-calendar-event {
		gap: 10px;
		color: var(--wx-color-font);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}

	.wx-event-calendar-multievent {
		padding: 0 6px;
		margin-bottom: 4px;
		border: 1px solid var(--wx-border-color);
		border-radius: 3px;
		color: var(--wx-color-primary-font);
		background-color: var(--wx-background);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.wx-event-calendar-readonly {
		cursor: default;
	}</style>
