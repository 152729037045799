<script>
	import { getContext } from "svelte";
	import { getRangeTimeString, localeContext } from "@xbs/lib-scheduler";

	export let event = {};
	export const calendar = {};

	const locale = getContext(localeContext);

</script>

<div class="wx-event-calendar-text">{event.text}</div>
<div class="wx-event-calendar-time">{getRangeTimeString(event, locale)}</div>

<style>
	.wx-event-calendar-text {
		font-weight: var(--wx-font-weight-md);
	}
	.wx-event-calendar-time {
		flex-shrink: 0;
		font-weight: var(--wx-font-weight);
	}</style>
