<script>
	import { getContext } from "svelte";

	export let events;
	export let search;

	$: filtered = events.filter(
		({ eventData }) =>
			!search ||
			eventData.text?.toLowerCase().includes(search.toLocaleLowerCase())
	);

	const templates = getContext("schedulerTemplates");

</script>

{#if events.length}
	<div class="wx-unassigned-events-wrapper">
		{#each filtered as { eventData } (eventData.id)}
			<svelte:component
				this={templates.unassignedEvent}
				event={eventData} />
		{/each}
	</div>
{/if}

<style>
	.wx-unassigned-events-wrapper {
		flex-grow: 1;
		overflow-y: auto;
	}</style>
