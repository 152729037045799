<script>
	import { fly } from "svelte/transition";
	import AgendaDay from "./AgendaDay.svelte";
	import { getContext } from "svelte";
	import { isAfter } from "date-fns";
	import { localeContext } from "@xbs/lib-scheduler";
	export let model = {};
	export let selected;
	$: ({ days, calendars, calculateMinutesLinePosition, activeDate } = model);
	const _ = getContext(localeContext).getGroup("scheduler");
	let y = 0;
	let prevDate = null;
	$: {
		const newDate = new Date(activeDate);
		y = isAfter(newDate, prevDate) ? 50 : -50;
		prevDate = newDate;
	}

</script>

{#if days.length}
	{#key activeDate}
		<div
			class="wx-event-calendar-agenda-wrapper"
			in:fly={{ duration: 700, y }}>
			{#each days as day (day.date)}
				<AgendaDay
					{day}
					{selected}
					{calendars}
					{calculateMinutesLinePosition} />
			{/each}
		</div>
	{/key}
{:else}
	<div class="wx-event-calendar-agenda-no-events">{_('No events')}</div>
{/if}

<style>
	.wx-event-calendar-agenda-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
	.wx-event-calendar-agenda-no-events {
		align-self: center;
		margin-top: 20px;
		display: flex;
		justify-content: center;
		flex: 1;
		align-items: center;
	}</style>
