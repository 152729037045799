<script>
	import { createEventDispatcher, getContext } from "svelte";

	export let calendar;
	const config = getContext("schedulerConfig");
	$: ({ readonly } = $config);
	$: ({ active, readonly: calendarReadonly, id, label, color } = calendar);

	const dispatch = createEventDispatcher();

	function toggle() {
		dispatch("action", {
			action: "update-calendar",
			data: {
				calendar: { ...calendar, active: !active },
				id,
			},
		});
	}
	function showPopup(e) {
		const rect = e.target.getBoundingClientRect();

		dispatch("sidebarAction", {
			action: "show-popup",
			data: {
				calendar,
				pos: { top: rect.top, left: rect.right },
			},
		});
	}

</script>

<div
	class="wx-event-calendar-wrapper"
	on:click={toggle}
	data-id="calendar-{id}">
	<div
		class="wx-event-calendar-checkbox"
		class:wx-event-calendar-active={active}
		style="--wx-border: {color?.border}">
		{#if active}<i class="wxi wxi-check" />{/if}
	</div>
	<div class="wx-event-calendar-label">{label}</div>
	{#if !calendarReadonly && !readonly}
		<i
			data-id="calendar-{id}-edit"
			class="wxi wxi-dots-v"
			on:click|stopPropagation={showPopup} />
	{/if}
</div>

<style>
	.wx-event-calendar-wrapper {
		display: flex;
		font-size: var(--wx-font-size);
		align-items: center;
		width: 100%;
		padding: 6px 12px;
	}

	.wx-event-calendar-wrapper:hover {
		background-color: var(--wx-background-hover);
	}
	.wx-event-calendar-wrapper:hover > .wxi-dots-v {
		display: flex;
	}

	.wx-event-calendar-checkbox {
		width: 18px;
		height: 18px;
		border: 1px solid var(--wx-border);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 12px;
		border-radius: 2px;
	}
	.wx-event-calendar-active {
		background-color: var(--wx-border);
	}

	.wx-event-calendar-label {
		flex-grow: 1;
	}
	.wxi-dots-v {
		color: var(--wx-color-font-disabled);
		font-size: var(--wx-line-height);
		margin-top: 2px;
		display: none;
	}

	.wxi-check {
		color: #ffff;
		font-size: 14px;
		margin-top: 2px;
	}</style>
