<script>
	import RecurringTree from "./RecurringTree.svelte";

	export let eventsGroup = [];
	export let search;

	$: eventsGroup = eventsGroup.filter(store => {
		const { eventData } = store.get()[0];
		return (
			!search ||
			eventData.text?.toLowerCase().includes(search.toLocaleLowerCase())
		);
	});

</script>

{#if eventsGroup.length}
	<div class="wx-unassigned-events-wrapper">
		{#each eventsGroup as store}
			<RecurringTree events={store} />
		{/each}
	</div>
{/if}

<style>
	.wx-unassigned-events-wrapper {
		flex-grow: 1;
		overflow-y: auto;
	}</style>
