<script>
	import { uid } from "@xbs/lib-scheduler";
	import { onMount } from "svelte";
	import { get } from "svelte/store";

	export let data;
	export let accept = "";
	export let disabled = false;
	export let multiple = true;
	export let folder = false;
	export let uploadURL = "";
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	export let ready = new Promise(() => {});

	let input;
	let drag;
	let count = 0;

	onMount(() => {
		input.webkitdirectory = folder;
	});

	$: if ($data.length) {
		upload();
	}
	function add(ev) {
		const files = Array.from(ev.target.files);
		files.forEach(f => addFile(f));
	}

	function drop(ev) {
		const items = Array.from(ev.dataTransfer.items);

		items.forEach(item => {
			const entry = item.webkitGetAsEntry();
			if (entry) flatten(entry);
		});

		drag = false;
		count = 0;
	}

	function flatten(item, path) {
		path = path || "";
		if (item.isFile) item.file(f => addFile(f));
		else if (item.isDirectory) {
			const dir = item.createReader();
			dir.readEntries(items =>
				items.forEach(item => {
					flatten(item, path + item.name + "/");
				})
			);
		}
	}

	function addFile(file) {
		const obj = {
			id: uid(),
			status: "client",
			name: file.name,
			file,
		};

		if (multiple) {
			data.update(store => [...store, obj]);
		} else data.set([obj]);
	}

	function upload() {
		const fileList = get(data); // use get(data) instead of $data due to https://github.com/sveltejs/svelte/issues/5052
		const files = fileList.filter(i => i.status === "client");
		if (!files.length) return;

		const requests = [];

		files.forEach(rec => {
			const formData = new FormData();
			formData.append("upload", rec.file);

			const config = {
				method: "POST",
				body: formData,
			};

			const request = fetch(uploadURL, config)
				.then(res => res.json())
				.then(
					data => {
						data.status = data.status || "server";
						updateData(rec.id, data);
					},
					() => updateData(rec.id, { status: "error" })
				)
				.catch(error => console.log(error));

			requests.push(request);
		});

		ready = Promise.all(requests)
			.then(() =>
				$data.filter(i => i.status === "server").map(i => i.file)
			)
			.catch(error => console.log(error));
	}

	function updateData(id, result) {
		data.update(store => {
			const ind = store.findIndex(i => i.id === id);
			store[ind] = { ...store[ind], ...result };
			return store;
		});
	}

	function dragenter() {
		if (count === 0) drag = true;
		count++;
	}

	function dragleave() {
		count--;
		if (count === 0) drag = false;
	}

	function open() {
		input.click();
	}

</script>

<div
	class="wx-event-calendar-uploader-label"
	class:active={drag}
	on:dragenter={dragenter}
	on:dragleave={dragleave}
	on:dragover|preventDefault
	on:drop|preventDefault={drop}>
	<input
		type="file"
		class="wx-event-calendar-uploader-input"
		bind:this={input}
		on:change={add}
		{accept}
		{disabled}
		{multiple} />
	<slot {open}>
		<div class="wx-event-calendar-uploader-dropzone">
			<span>
				Drop files here or
				<span
					class="wx-event-calendar-uploader-action"
					on:click={open}>select files</span>
			</span>
		</div>
	</slot>
</div>

<style>
	.wx-event-calendar-uploader-label {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.wx-event-calendar-uploader-label.active {
		background-color: var(--wx-secondary-color-hover);
	}
	.wx-event-calendar-uploader-input {
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
	}

	.wx-event-calendar-uploader-dropzone {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 70px;
		border: 1px dashed var(--wx-color-primary);
		padding: 10px;
	}
	.wx-event-calendar-uploader-action {
		cursor: pointer;
		color: var(--wx-color-primary);
		text-decoration: underline;
	}</style>
