<script>
	import { getContext } from "svelte";
	import { getEventStyles, readonlyConfig } from "@xbs/lib-scheduler";
	import { isPast } from "date-fns";

	export let event = {};
	export let calendars = [];
	export let selected;

	const config = getContext("schedulerConfig");
	const tempaltes = getContext("schedulerTemplates");
	$: ({ readonly, dimPastEvents } = $config);

	$: eventData = event.eventData;
	$: calendar = calendars.find(c => c.id === eventData.type);

	$: ({ resize, drag, r } = readonlyConfig(eventData, calendar, readonly));
	$: id = eventData?.id;

</script>

<div
	class="wx-event-calendar-event-box {r ? 'wx-event-calendar-readonly' : ''} wx-event-calendar-week-oneday-event"
	use:getEventStyles={{ event, backgroundFactor: 'background', calendars, dimPastEvents, isPast: isPast(eventData?.end_date) }}
	class:wx-event-calendar-selected={$selected?.id === id}
	data-drag={drag}
	data-disable-transition={true}
	data-id-type={typeof id}
	data-id={id}>
	<svelte:component this={tempaltes.weekEvent} event={eventData} {calendar} />
	{#if resize}
		<i
			data-drag="source"
			data-id-type={typeof id}
			class="wx-event-calendar-resizer wxi-dots-h"
			data-resizer={id} />
	{/if}
</div>

<style css>
	.wx-event-calendar-event-box {
		flex-direction: column;
		position: absolute;
		border: 1px solid var(--wx-border-color);
		border-left: 5px solid var(--wx-border-color);
		background-color: var(--wx-background);
		cursor: default;
		border-radius: 3px;
		color: var(--wx-color-primary-font);
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
		display: flex;
		align-items: center;
		align-items: flex-start;
		padding: 6px;
		min-height: var(--wx-line-height-sm);
		transition: left 0.2s, width 0.2s;
	}
	.wx-event-calendar-readonly {
		cursor: default;
	}
	.wx-event-calendar-resizer {
		display: flex;
		justify-content: center;
		cursor: n-resize;
		width: 100%;
		flex-shrink: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		line-height: 10px;
		visibility: hidden;
		color: var(--wx-color-primary-font);
		background-color: var(--wx-background);
	}

	:global(.wx-event-calendar-event-box--small) {
		justify-content: space-between;
		gap: 2px;
		flex-direction: row !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	:global(.wx-event-calendar-event-box--small) .wx-event-calendar-resizer {
		background-color: unset !important;
	}
	.wx-event-calendar-event-box:hover .wx-event-calendar-resizer {
		visibility: visible !important;
	}
	.wx-event-calendar-selected {
		box-shadow: var(--wx-popup-shadow);
	}</style>
