<script>
	import { localeContext } from "@xbs/lib-scheduler";
	import { getContext } from "svelte";
	import { format } from "date-fns";

	export let event;

	$: ({ id, text, end_date } = event);

	const locale = getContext(localeContext);
	const _ = locale.getGroup("scheduler");
	const dateFormat = _("unassignFormat");
	const _dates = getContext(localeContext).getRaw().dateFnsLocale;

</script>

<div class="wx-unassigned-event" data-id={id} data-drag="source">
	<span>{text}</span>
	<span
		class="wx-event-calendar-unassign-date">{end_date ? format(end_date, dateFormat, _dates) : ''}</span>
</div>

<style>
	.wx-unassigned-event {
		display: flex;
		justify-content: space-between;
		padding: 6px 10px;
		cursor: pointer;
		text-overflow: ellipsis;
	}
	.wx-event-calendar-unassign-date {
		white-space: nowrap;
	}</style>
