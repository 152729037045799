<script>
	import ColorPickerSchema from "../Controls/ColorPickerSchema.svelte";
	import { getContext, createEventDispatcher } from "svelte";
	import { Portal, Popup, Field } from "@wx/svelte-core";
	import { localeContext } from "@xbs/lib-scheduler";
	export let pos = {};
	export let calendar = {};
	const _ = getContext(localeContext).getGroup("scheduler");
	const colors = getContext("schedulerColors");
	const dispatch = createEventDispatcher();

	$: {
		dispatch("action", {
			action: "update-calendar",
			data: {
				id: calendar.id,
				calendar: {
					...calendar,
				},
			},
		});
	}

	function dispatchAction(action) {
		dispatch("sidebarAction", { action });
	}

</script>

<Portal>
	<Popup {...pos} cancel={() => dispatchAction('close')}>
		<div class="wx-event-calendar-wrapper">
			<div class="wx-event-calendar-item wx-event-calendar-colorpicker">
				<Field label={_('Color')} position="top" let:id>
					<ColorPickerSchema
						colors={$colors}
						bind:color={calendar.color} />
				</Field>
			</div>
			<div
				class="wx-event-calendar-item"
				on:click={() => dispatchAction('edit')}>
				{_('Edit')}
			</div>
			<div
				class="wx-event-calendar-item wx-event-calendar-delete"
				on:click={() => dispatchAction('delete')}>
				{_('Delete')}
			</div>
		</div>
	</Popup>
</Portal>

<style>
	:global(.popup) {
		overflow: visible !important;
	}
	.wx-event-calendar-wrapper {
		width: 244px;
	}
	.wx-event-calendar-item {
		padding: 6px 11px;
		cursor: pointer;
	}
	.wx-event-calendar-colorpicker {
		padding-bottom: 0;
	}
	.wx-event-calendar-delete {
		color: var(--wx-color-danger);
		border-top: var(--wx-border);
	}</style>
