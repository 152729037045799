<script>
	import { getContext } from "svelte";

	import { timelineHeaderStyles } from "@xbs/lib-scheduler";
	export let header;
	export let index;
	export let colsWidth;
	const templates = getContext("schedulerTemplates");
	$: ({ cols, format } = header);

</script>

{#each cols as col (col.date)}
	<th
		class="wx-event-calendar-timeline-header-date wx-event-calendar-timeline-header-cell"
		use:timelineHeaderStyles={{ colsWidth, index }}
		colspan={col.colspan}
		data-day-toggle={true}
		data-cell={col.date.valueOf()}>
		<svelte:component
			this={templates.header}
			date={col.date}
			dateFormat={format} />
	</th>
{/each}

<style>
	.wx-event-calendar-timeline-header-cell {
		padding: 3px;
		position: sticky;
		z-index: 1;
		height: 32px;
		border-right: var(--wx-border);
		border-bottom: var(--wx-border);
		background-color: var(--wx-background);
		box-sizing: border-box;
		overflow: hidden;
		display: table-cell;
		color: var(--wx-color-font-alt);
		font-weight: var(--wx-font-weight);
	}

	.wx-event-calendar-timeline-header-date {
		border-right: var(--wx-border);
		flex-shrink: 0;
		padding: 0;
		z-index: 1;
		position: sticky;
	}</style>
