<script>
	export let active;
	export let tabs = [];

	$: if (!active) {
		handleClick(tabs[0].id);
	}

	function handleClick(id) {
		active = id;
	}

</script>

<div class="wx-event-calendar-tabbar" on:click|stopPropagation>
	{#each tabs as { id, label } (id)}
		<div
			class="wx-event-calendar-tab"
			class:wx-active-tab={active === id}
			on:click={() => handleClick(id)}>
			{label}
		</div>
	{/each}
</div>

<style>
	.wx-event-calendar-tabbar {
		display: flex;
		width: 100%;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
		height: 33px;
	}
	.wx-event-calendar-tab {
		padding: 3px 30px;
	}
	.wx-active-tab {
		border-bottom: 2px solid var(--wx-color-primary);
	}</style>
