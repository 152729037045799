<script lang="ts">import { writable } from "svelte/store";
import UploaderList from "./UploaderList.svelte";
import Uploader from "./Uploader.svelte";
export let field;
export let values;
$: files = writable($values[field.key] || []);
let ready = false;
$: {
    if (ready) {
        $values[field.key] = $files;
    }
    ready = true;
}
</script>

<div class="wx-event-calendar-uploader">
	<Uploader
		bind:value={$values[field.key]}
		data={files}
		uploadURL={field.uploadURL}
		{...field.config} />
	<UploaderList data={files} />
</div>

<style>
	.wx-event-calendar-uploader {
		gap: 10px;
		display: flex;
		flex-direction: column;
	}</style>
